import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../helpers";
import CompanyLogo from "../CompanyLogo";

export const links = [
  { name: "Practice Areas", href: "/practice-areas" },
  { name: "Team", href: "/team" },
  { name: "Contact", href: "/contact" },
];

type NavbarProps = {
  pathname: string;
  company: {
    companyName: string;
    description: string;
    logo: string;
    favicon: string;
    address: string;
    linkedin: string;
    phoneNumber: string;
    faxNumber: string;
  };
};

export default function Navbar({ pathname, company }: NavbarProps) {
  return (
    <Disclosure
      as="nav"
      className="bg-black bg-opacity-90 fixed z-10 w-[100%] shadow-xl"
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-6">
            <div className="flex h-24 items-center justify-between">
              <div className="flex items-center flex-1">
                <div className="flex-shrink-0 m-auto sm:m-0">
                  <CompanyLogo company={company} />
                </div>
                <div className="hidden sm:ml-auto sm:block">
                  <div className="flex space-x-4">
                    <NavbarLinks pathname={pathname} isMobileMenu />
                  </div>
                </div>
              </div>
              <div className="flex sm:hidden absolute right-0 m-6">
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-brand hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="flex flex-col items-start space-y-1 px-4 pb-3 pt-2">
              <NavbarLinks pathname={pathname} isMobileMenu />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

function NavbarLinks({
  pathname,
  isMobileMenu = false,
}: {
  pathname: string;
  isMobileMenu?: boolean;
}) {
  return (
    <>
      {links.map(({ name, href }) => (
        <NavbarButton
          key={name}
          href={href}
          isMobileMenu={isMobileMenu}
          className={classNames(
            pathname === href ? "border-b-2 border-brand" : "",
            "text-white px-3 py-2 text-sm font-medium uppercase hover:text-brand"
          )}
        >
          {name}
        </NavbarButton>
      ))}
    </>
  );
}

function NavbarButton({
  children,
  isMobileMenu,
  ...props
}: {
  children: React.ReactNode;
  href: string;
  className: string;
  isMobileMenu: boolean;
}) {
  if (isMobileMenu) {
    return (
      <Disclosure.Button as="a" {...props}>
        {children}
      </Disclosure.Button>
    );
  }
  return <a {...props}>{children}</a>;
}
