interface CompanyLogoProps {
  company: {
    companyName: string;
    logo: string;
  };
}

const CompanyLogo = ({ company }: CompanyLogoProps) => {
  return (
    <a href="/" className="flex items-center">
      <img
        className="flex-1  h-14 w-auto"
        src={company.logo}
        alt={company.companyName}
      />
    </a>
  );
};

export default CompanyLogo;
